import styles from "./Footer.module.scss"
import React from "react"
import cx from "clsx"

import Container from "../../layouts/Container/Container"
import Text from "../Text/Text"
import { BrandNavbar } from "../Brand/Brand"
import Link from "../Link/Link"
import BrandInstagramIcon from "../icons/BrandInstagramIcon"
import BrandFacebookIcon from "../icons/BrandFacebookIcon"

const FooterMenu = ({ to, children }) => {
  return (
    <li className={styles.menu}>
      <Link to={to}>{children}</Link>
    </li>
  )
}

const Footer = ({ className, ...restProps }) => {
  return (
    <>
      <Container bleed className={styles.root}>
        <Container narrow>
          <Container
            bleed
            className={cx(styles.wrapper, className)}
            {...restProps}
          >
            <section>
              <BrandNavbar className={styles.logo} title="Talent Indonesia" />
              <div>
                <Text as="p" heading5 style={{ paddingBottom: 4 }}>
                  Wisma Hafid
                </Text>
                <Text as="p" small>
                  Jl. Raya Narogong KM 18, Limus Nunggal, Cileungsi, <br />{" "}
                  Bogor 16820, Indonesia
                </Text>
              </div>
            </section>
            <ul className={styles.menuList}>
              <FooterMenu to="/tentang-visa-ssw">Visa SSW</FooterMenu>
              <FooterMenu to="/pelatihan-kerja">Pelatihan Kerja</FooterMenu>
              <FooterMenu to="/info-ujian-ssw">Info Ujian SSW</FooterMenu>
              <FooterMenu to="/tentang-kami">Tentang Kami</FooterMenu>
            </ul>
            <aside className={styles.menuList}>
              <Text small as="p">
                Terhubung dengan kami melalui social media
              </Text>
              <ul className={styles.socialList}>
                <a
                  href="https://www.instagram.com/talentid.info/"
                  target="_blank"
                  className={styles.social}
                >
                  <BrandInstagramIcon className={styles.icon} />
                </a>
                <a
                  href="https://www.facebook.com/talentindonesiajaya/"
                  target="_blank"
                  className={styles.social}
                >
                  <BrandFacebookIcon className={styles.icon} />
                </a>
              </ul>
            </aside>
          </Container>
          <Text as="p" small className={styles.copy}>
            &copy; 2022 Talent Indonesia. All rights reserved
          </Text>
        </Container>
      </Container>
    </>
  )
}

export default Footer
