import styles from "./Banner.module.scss"
import React from "react"
import cx from "clsx"
import Container from "../../layouts/Container/Container"
import { Box } from "tenka"

const Banner = ({
  children,
  layout = "default",
  className,
  tone,
  space,
  py = space,
  ...restProps
}) => {
  return (
    <div
      className={cx(styles.root, {
        [styles[tone]]: tone,
        [className]: className,
      })}
      {...restProps}
    >
      <Container
        narrow={layout === "narrow"}
        post={layout === "post"}
        bleed={layout === "bleed"}
      >
        <Box py={py}>{children}</Box>
      </Container>
    </div>
  )
}

export default Banner
