import styles from "./NavbarWeb.module.scss"
import React from "react"
import cx from "clsx"

import { BrandNavbar as BrandLogo } from "../Brand/Brand"

import Container from "../../layouts/Container/Container"

import Link from "../Link/Link"
import NavbarPrimary from "../NavbarPrimary/NavbarPrimary"
import NavbarMenu from "../NavbarMenu/NavbarMenu"
import AvatarArea from "../AvatarArea/AvatarArea"

const NavbarWeb = ({ bleed, light, children, className, ...restProps }) => {
  return (
    <Container
      className={cx({ [styles.wrapper]: !light, [styles.light]: light })}
      bleed
    >
      <Container
        as="nav"
        bleed
        className={cx({
          [styles.root]: true,
          [className]: className,
        })}
        {...restProps}
      >
        <nav className={styles.brandWrapper}>
          <Link to="/" className={styles.brand}>
            <BrandLogo
              style={{ height: 48, marginTop: -8, marginBottom: -8 }}
            />
          </Link>
        </nav>
        <NavbarPrimary>
          <NavbarMenu to="/">Home</NavbarMenu>
          <NavbarMenu to="/lowongan">Lowongan</NavbarMenu>
        </NavbarPrimary>
        <nav className={styles.accountWrapper}>
          <div className={styles.menuDesktop}>
            <NavbarMenu to="/tentang-visa-ssw">Visa SSW</NavbarMenu>
            <NavbarMenu to="/pelatihan-kerja">Pelatihan Kerja</NavbarMenu>
            <NavbarMenu to="/info-ujian-ssw">Info Ujian SSW</NavbarMenu>
            <NavbarMenu to="/tentang-kami">Tentang Kami</NavbarMenu>
          </div>
          <AvatarArea />
        </nav>
      </Container>
    </Container>
  )
}

export default NavbarWeb
