import styles from "./DefaultMarketing.module.scss"
import React from "react"
import Head from "next/head"
import cx from "clsx"
import dynamic from "next/dynamic"

import NavbarWeb from "../../components/NavbarWeb/NavbarWeb"
import Footer from "../../components/Footer/Footer"
import Container from "../Container/Container"
import MobileMenu from "../../components/MobileMenu/MobileMenu"
import BannerEmail from "../../components/BannerEmail/BannerEmail"

import FabContact from "../../components/FabContact/FabContact"
import Banner from "../../components/Banner/Banner"
import Text from "../../components/Text/Text"

const DefaultMarketing = ({
  title,
  gray,
  children,
  withFooter,
  withFab,
  withMobileMenu,
  withBannerInfo,
  noEmailReminder,
}) => {
  return (
    <>
      <Head>
        <title>{title || "Talent Indonesia"}</title>
      </Head>
      <NavbarWeb />
      <BannerEmail />
      <Container
        bleed
        as="main"
        className={cx(styles.fullContent, { [styles.gray]: gray })}
      >
        {withBannerInfo && (
          <Banner tone="brand" space="sm">
            <Text small as="p" align="center">
              Sementara website ini hanya menampilkan lowongan SSW Jepang.
              Lowongan kerja di Eropa dan lainnya dapat dilihat pada halaman{" "}
              <a
                href="https://www.facebook.com/talentindonesiajaya"
                target="_blank"
                className={styles.link}
              >
                Facebook
              </a>{" "}
              dan{" "}
              <a
                href="https://www.instagram.com/talentid.info"
                target="_blank"
                className={styles.link}
              >
                Instagram
              </a>{" "}
              Talent Indonesia.
            </Text>
          </Banner>
        )}
        {children}
      </Container>
      {withFooter && <Footer />}
      {withFab && <FabContact />}
      {withMobileMenu && <MobileMenu />}
    </>
  )
}

export default DefaultMarketing
